<template>
<div>
    <div class="dashboard-widget-tabs" ref="top">
        <div class="dashboard-widget-tabs-content">
            <h2>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="rightnav-svg rightnav-svg-page"><path d="M160 320c53.02 0 96-42.98 96-96c0-53.02-42.98-96-96-96C106.1 128 64 170.1 64 224C64 277 106.1 320 160 320zM192 352H128c-70.69 0-128 57.3-128 128c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32C320 409.3 262.7 352 192 352z" class="fa-primary"></path><path d="M592 0h-384C181.5 0 160 22.25 160 49.63V96c23.42 0 45.1 6.781 64 17.8V64h352v288H287.3c22.07 16.48 39.54 38.5 50.76 64h253.9C618.5 416 640 393.8 640 366.4V49.63C640 22.25 618.5 0 592 0zM312 218l55.01 54.99c9.375 9.375 24.6 9.375 33.98 0L473 201l24.25 24.25C508.6 236.6 528 228.6 528 212.5V124c0-6.625-5.375-12-12-12h-88.5c-16.12 0-24.12 19.38-12.75 30.75L439 167L384 222l-55.01-55.01c-9.375-9.375-24.6-9.375-33.98 0L280.3 181.8C285 195.1 288 209.1 288 224c0 6.875-.9999 13.38-2 20L312 218z" class="fa-secondary"></path></svg>
                <span class="page-title-svg">
                    تحلیل‌ها
                </span>
            </h2>
            <!-- <p>خلاصه توضیحات کوتاه درباره این بخش از داشبورد شبکه اطلاع رسانی ارز طلا و سکه</p> -->
            <div class="dashboard-widget-h-btns dashboard-widget-h-btns-flex">
                <div class="switcher-btns m-left m-top">
                    <router-link :to="{ name: 'PublicTechnical'}" class="tgju-btn tgju-btn-light-orange">
                        <i class="uil uil-chart"></i> <span>تابلو تحلیل‌ها</span>
                    </router-link>
                    <router-link :to="{ name: 'PublicAnalysts'}" class="tgju-btn tgju-btn-light-orange">
                        <i class="uil uil-book-reader"></i> <span>تحلیل‌گرها</span>
                    </router-link>
                </div>
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
        <div class="widget-tabs is-flex public-technical-flex">
            <div class="widget-tabs-nav">
                <span v-if="this.$helpers.userAuth()" class="tgju-btn tgju-btn-primary m-left m-top m-bottom-mobile" @click.prevent="open_new_technical_modal()">
                    <i class="uil uil-plus-circle"></i> <span class="hide-m">افزودن تحلیل جدید </span>
                </span>
                
                <div :class="'tgju-btn tgju-btn-light-red m-left m-top m-bottom-mobile ' + (hot ? 'active' : '') " @click="selectHot()"  v-tooltip="'داغ‌ترین‌ها'">
                    <i class="uil uil-fire color-red"></i>
                    <span class="hide-m" style="margin-right: 3px;">داغ‌ترین‌ها</span>
                </div>
                <div class="widget-select w-170 m-left m-top">
                    <v-select2 v-model="selected" :clearable="false" label="value" :reduce="(option) => option.key" :options="[{key: 'newest', value: 'جدیدترین'},{key: 'oldest', value: 'قدیمی ترین'}]" @input="selectFilter()">
                        <i class="uil uil-list-ui-alt"></i>
                    </v-select2>
                </div>
                <div class="widget-select w-170 m-left m-top">
                    <v-select2 v-model="sortByTime" :clearable="false" label="value" :reduce="(option) => option.key" :options="[{key: 'all', value: 'بازه زمانی'},{key: 'day', value: 'روز گذشته'},{key: 'week', value: 'هفته گذشته'},{key: 'month', value: 'ماه گذشته'},{key: '3-month', value: '۳ ماه گذشته'},{key: '6-month', value: '۶ ماه گذشته'},{key: 'year', value: 'سال گذشته'}]" @input="selectTime()">
                        <i class="uil uil-list-ui-alt"></i>
                    </v-select2>
                </div>
                <div class="widget-select w-185 m-left m-top">
                    <!-- TODO: {key: 'btc', value: 'بیت کوین'},{key: 'eth', value: 'اتریوم'},{key: 'gold', value: 'طلا'},{key: 'seke', value: 'سکه'},{key: 'dollar', value: 'دلار'},{key: 'oil', value: 'نفت و انرژی'},{key: 'bource', value: 'بورس'} -->
                    <v-select2 v-model="selectedMarket" :clearable="false" label="value" :reduce="(option) => option.key" :options="technicalsCategoryList" @input="selectMarket()">
                        <i class="uil uil-list-ui-alt"></i>
                    </v-select2>
                </div>
            </div>
            <div class="widget-tabs-btn flex-btn">
                <div v-if="this.$helpers.userAuth()" :class="'tgju-btn tgju-btn-light-orange m-left m-top m-bottom-mobile ' + (selectedMyTechnicals ? 'active' : '') " @click="selectMyTechnicals()" v-tooltip="'تحلیل‌های من'">
                    <i class="uil uil-chart"></i>
                    <span class="hide-m" style="margin-right: 5px;">تحلیل‌های من</span>
                </div>
                <span v-if="this.$helpers.userAuth()" @click="selectBookmark()" :class="'m-top tgju-btn tgju-btn-dark-yellow ' + (bookmark ? 'active' : '') "  v-tooltip="'بوک‌مارک‌ها'">
                    <i class="uil uil-bookmark-full "></i>
                    <span class="hide-m"> بوک‌مارک‌ها</span>
                </span>
                <!-- <router-link :to="{ name: 'PublicAnalysts'}" class="tgju-btn tgju-btn-dark-orange m-right m-top">
                    <i class="uil uil-book-reader"></i> <span class="hide-m">تحلیل‌گرها</span>
                </router-link> -->
                <!-- <div v-if="(!widgetLoad && showBackButton) || this.$helpers.userAuth()" @click.prevent="back()" class="m-right m-top tgju-btn tgju-btn-light" v-tooltip="'بازگشت'"><i class="uil uil-angle-left-b"></i> <span class="hide-m-min">بازگشت </span></div> -->
            </div>
        </div>
    </div>
    <div class="row tgju-widgets-row dashboard-widgets-technical-list">
        <PublicTechnicalList :filter="selected" :hot="selectedHot" :my_technicals="selectedMyTechnicals" :time="sortByTime" :market="selectedMarket" :bookmark="bookmark" :key="componentReload"></PublicTechnicalList>
    </div>
</div>
</template>

<script>
// این ویو برای تحلیل های عمومی مورد استفاده قرار میگیرد
import PublicTechnicalList from '@/components/Technical/PublicTechnicalList.vue'
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'PublicTechnical',
    components: {
        PublicTechnicalList,
    },
    data: function () {
        return {
            showBackButton: false,
            technicalsCategoryList: [{key: 'all', value: 'بازار معاملاتی'}],
            componentReload: 0,
            selected: 'newest',
            selectedHot: '',
            sortByTime: 'all',
            selectedMarket: 'all',
            selectedMyTechnicals: false,
            bookmark: false,
            hot: false,
            widget: {},
            data: {},
        }
    },
    mounted () {
        this.load_technicals_category_list();
        if (this.$router.history._startLocation == '/technical'){
            this.showBackButton = true;
        }
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        load_technicals_category_list() {
            this.$helpers
                .makeRequest("GET", '/technical/technicals-category-list')
                .then((api_response) => {
                    api_response.data.response.category.forEach(element => {
                        this.technicalsCategoryList.push({key: element.slug_en, value: element.name});
                    });
                    
                });
        },
        // این متد جهت باز کردن یک مدال برای افزودن تحلیل جدید استفاده می شود
        open_new_technical_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_NewTechnical_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'NewTechnical.vue',
                config: {
                    title: 'تحلیل جدید',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: false,
                    data: {
                        // جهت ریفرش کردن مدال که از داده های قدیمی پاک شود
                        refresh: Math.random(),
                    },
                }
            };

            this.$root.$emit('open_modal3', modal_data);

            // وقتی روی دکمه بستن مدال کلیک شد
            // در کال بک فانشکن لیست را آپدیت میکنیم . 
            this.$root.$on(callbackEvent, (response) => {
                this.refresh++;
            });

            $('body').addClass('show-modal3');
        },
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل هایی که بو مارک شده اند
        selectBookmark() {
            if (this.bookmark) {
                this.bookmark = false;
            } else if (!this.bookmark) {
                this.bookmark = true;
            }
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        // قدیمی ترین ، محبوب ترین ، جدیدترین
        selectFilter() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        selectTime() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت مارکت تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        selectMarket() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        // متد برای نمایش داغ ترین یا همان محبوب ترین تحلیل ها
        selectHot() {
            if (this.hot) {
                this.selectedHot = '';
                this.hot = false;
            } else if (!this.hot) {
                this.selectedHot = 'popular';
                this.hot = true;
            }
            this.componentReload = Math.random();
        },
        selectMyTechnicals() {
            if (this.selectedMyTechnicals) {
                this.selectedHot = '';
                this.selectedMyTechnicals = false;
            } else if (!this.selectedMyTechnicals) {
                this.selectedHot = 'popular';
                this.selectedMyTechnicals = true;
            }
            this.componentReload = Math.random();

        },
        // دکمه بازگشت
        back() {
            this.$router.push({ name : 'NewTechnical' });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Technical.vue',
                title: 'تحلیل‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
